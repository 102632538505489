import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby'
import Layoutas from '../../components/Layout';
import RefDataDate from '../../components/RefDataDate';
import Mapbox from '../../mapbox'
import LegendCard from '../../mapbox/LegendCard'
import Chart from '../../components/Chart';
import Calculate from '../../util/calculate';

import { Select, Table, Modal } from 'antd';
import '../index.css';

import TumbonAddr from '../../util/TumbonAddr'

import newnew from '/images/wepik-photo-mode-2022619-143425.webp';

import api from '../../util/api';

const { Option } = Select;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const PagePMS = (props) => {
  const [initData, setInitData] = useState(null)

  const [filter, setFilter] = useState({
    view: 'country',
    year: "2564",
  });
  const [data, setData] = useState({
    group_institute: [],
    group_province: [],
    group_target: [],
    group_project_target: []
  })
  const [data_map, setDataMap] = useState([])
  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#a5d6a7',])

  const is_Click = useRef(false);
  const [visible_modal, setVisibleModal] = useState(false);
  const [dataTable, setDataTable] = useState({ province: "", count: 0, data: [] });
  const [legend_data, setLegendData] = useState({
    title: '',
    subtitle: '',
    data: [],
    footer: ''
  })

  const [windowDimensions, setWindowDimensions] = React.useState({ width: 0, height: 0 });

  useEffect(() => {
    setWindowDimensions(getWindowDimensions());
  }, [])

  useEffect(async () => {
    let dataSummaryAllyear = await api.getInitData("pms-overview");
    setInitData(dataSummaryAllyear);
  }, [])

  useEffect(() => {
    if (initData) {
      data.group_institute = initData.group_institute.filter(item => item.year === filter.year).sort((a, b) => b.countProject - a.countProject)
      data.group_province = initData.group_province.filter(item => item.year === filter.year).sort((a, b) => b.countProject - a.countProject)
      data.group_target = initData.group_target.filter(item => item.year === filter.year).sort((a, b) => b.countProject - a.countProject)
      data.group_project_target = initData.group_project_target.filter(item => item.year === filter.year).sort((a, b) => b.name - a.name)

      setData({ ...data })

      let response = onProcessData({
        province: filter['province_name'],
        layer: filter.view
      }, convertMapContent(filter.view, data.group_province))
      setDataMap([
        { id: 'border', layer: filter.view, data: response.data }
      ])
      let data_legend = [
        { name: 'จำนวนโครงการ', value: response.data.reduce((acc, cur) => acc + cur.pms, 0), unit: 'โครงการ' },
      ]
      setLegendData({
        ...legend_data,
        title: filter.province_name,
        subtitle: filter.city_name,
        data: data_legend,
      })
    }
  }, [filter, initData])

  const convertMapContent = (layer, data) => {
    let res = []
    const province = [...new Set(TumbonAddr.map(item => item.provincename))].map(item => { return { province: item, total: 0, pms: 0 } });
    data.forEach((item) => {
      let total = (item.countProject)
      let count = (item.countProject)
      let index = province.findIndex(ele => ele.province === item.province_name)
      !province[index]['total'] ? province[index]['total'] = total : province[index]['total'] += total
      !province[index]['pms'] ? province[index]['pms'] = count : province[index]['pms'] += count
      res = province

    })
    return res
  }

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }

    let result = []
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    result.map(element => {
      if (!isFinite(element['pms'] / element['total'])) {
        element['percent'] = 0
      } else {
        element['percent'] = ((element['pms'] / result.map(item => item.pms).reduce((a, b) => a + b, 0)) * 100)
      }
      return element
    })
    let data_color = []
    if (filter.region !== 'ทั้งหมด') {
      data_color = [
        { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item.pms)), count: 0, text: `มาก` },
        { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item.pms)), count: 0, text: `ปานกลาง` },
        { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item.pms)), count: 0, text: `ปานกลาง` },
        { color: border_color[3], value: 0, count: 0, text: `น้อย` },
        { color: border_color[4], value: -100, count: 0, text: `น้อย` },
      ]
    } else {
      data_color = [
        { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item.pms)), count: 0, text: `มาก` },
        { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item.pms)), count: 0, text: `ปานกลาง` },
        { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item.pms)), count: 0, text: `ปานกลาง` },
        { color: border_color[3], value: 0, count: 0, text: `น้อย` },
        { color: border_color[4], value: -100, count: 0, text: `น้อย` },
      ]
    }
    result.forEach(element => {
      element['color'] = _genColorRange(data_color, element.pms)
      // element['opacity'] = 0.5
      element['percent'] = parseFloat(element['percent'].toFixed(2))
    })
    let res = { data: result, notic: data_color }
    return res
  }

  const onclickBorder = (layer, filterBorder) => {
    if (layer === 'tumbon') return;
    if (!is_Click.current) {
      is_Click.current = true
      dataTable.province = filterBorder.province
      dataTable.count = data.group_project_target.filter(item => (item.province ? item.province.includes(filterBorder.province) : true)).length
      let tmp = data.group_project_target.filter(item => (item.province ? item.province.includes(filterBorder.province) : false))
      dataTable.data = tmp.map(item => {

        return {
          name: item.name,
          target: <ul className='m-0'>{
            item.target.map((element, id) => element ? <li>
              <div className='flex flex-row'><div className='bg-black rounded-full w-[5px] h-[5px] my-auto mr-2'></div>{element}</div>
            </li> : <></>)
          }</ul>,
          province: item.province.length,
          budget: item.budget
        }
      })
      setDataTable({ ...dataTable })
      setVisibleModal(true)
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  return (
    <Layoutas Role={['eef']}>
      <Modal
        title={<div className='text-2xl text-green-600 font-bold'>{`จังหวัด${dataTable.province}`}</div>}
        centered
        cancelButtonProps={{ style: { display: 'none' } }}
        visible={visible_modal}
        onOk={() => setVisibleModal(false)}
        onCancel={() => setVisibleModal(false)}
        width={1000}
      >
        <div className='text-xl text-[#0E3165] font-bold'>{`จำนวนโครงการทั้งหมด ${dataTable.count.toLocaleString("en-US")} โครงการ`}</div>
        <Table
          size="small"
          bordered
          style={{ width: '100%' }}
          pagination={{ pageSize: 7 }}
          columns={[
            {
              title: 'ชื่อโครงการ',
              dataIndex: 'name',
              key: 'name',
              align: 'left',
              fixed: 'left',
              render(text, record) {
                return {
                  props: {
                    style: { textAlign: "left" }
                  },
                  children: <span>{text}</span>
                };
              },
              sorter: (a, b) => {
                if (a.name > b.name) {
                  return -1;
                }
                if (b.name > a.name) {
                  return 1;
                }
                return 0;
              }
              //  (a, b) => a.key - b.key,
            },
            {
              title: 'กลุ่มเป้าหมาย',
              dataIndex: 'target',
              key: 'target',
              align: 'left',
              render(text, record) {
                return {
                  props: {
                    style: { textAlign: "left" }
                  },
                  children: <div>{text}</div>
                };
              },
              // sorter: (a, b) => a.target[0] - b.target[0],
            },
            {
              title: 'จำนวนจังหวัดที่ครอบคลุม',
              dataIndex: 'province',
              key: 'province',
              align: 'center',
              render(text, record) {
                return {
                  props: {
                    style: { textAlign: "center" }
                  },
                  children: <div>{text.toLocaleString("en-US")}</div>
                };
              },
              sorter: (a, b) => a.province - b.province,
            },
            {
              title: 'งบประมาณ',
              dataIndex: 'budget',
              key: 'budget',
              align: 'center',
              render(text, record) {
                return {
                  props: {
                    style: { textAlign: "center" }
                  },
                  children: <div>{text.toLocaleString("en-US")}</div>
                };
              },
              sorter: (a, b) => a.budget - b.budget,
            },

          ]}
          dataSource={dataTable.data}
        >
        </Table>
      </Modal>
      < div className='bg-gray-200'>
        <div style={{
          width: '100%',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          backgroundImage: `url(${newnew})`,
          display: 'flex'
        }} >
          <h1 className="font-bold text-2xl md:text-3xl xl:text-5xl text-center text-white mt-[120px] w-full ">ระบบบริหารจัดการโครงการ (PMS)</h1>
        </div>
        <div className="max-w-[1300px] rounded-3xl m-auto -mt-[70vh] p-2 md:p-4 lg:8 bg-white bordered">
          <div className="font-bold text-xl lg:text-3xl mb-2 text-[#0E3165] text-center">จำนวนพื้นที่ดำเนินงานทั้งหมดของ กสศ.</div>
          <div className="flex flex-row mb-2 content-center">
            <div>
              <span className="text-sm m-0 ml-[9px]" >ปี: </span>
              <Select
                showSearch
                placeholder="ปีการศึกษา"
                value={filter.year}
                style={{ width: 180, margin: 4 }}
                onChange={(val) => {
                  setFilter({ ...filter, year: val })
                }}
              >
                {
                  [...new Set(['2565', '2564', '2563', '2562', '2561'])]
                    .filter(x => x !== 'ไม่ระบุ')
                    .sort()
                    .map(x => <Option value={x}>{x}</Option>)
                }
              </Select>
            </div>
          </div>

          <div className="relative w-full h-full rounded overflow-hidden shadow-card bg-slate-500">
            <Mapbox
              id={'map-pms'}
              data={data_map}
              zoom={5.2}
              className="map-pms-container"
              style={"border_basic"}
              filter={filter}
              notic_title={'จำนวนพื้นที่ดำเนินงานทั้งหมดของ กสศ.'}
              onclickBorder={onclickBorder}
            />
            <div className='absolute top-[10px] left-[10px] z-1'>
              <LegendCard {...legend_data} />
            </div>
            <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
              <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
                <span className='font-bold text-sm md:text-base center mx-1 mb-2'>สัดส่วนกลุ่มเป้าหมายที่ได้รับความช่วยเหลือ</span>
                <div className='flex'>
                  <div className='flex flex-col'>
                    <div class={`h-3 w-10 md:w-16 bg-[#1b5e20]`}>
                    </div>
                    <div class="font-bold text-left">
                      มาก
                    </div>
                  </div>
                  <div className='flex flex-col'>
                    <div class={`h-3 w-10 md:w-16 bg-[#388e3c]`}>
                    </div>
                  </div>
                  <div className='flex flex-col'>
                    <div class={`h-3 w-10 md:w-16 bg-[#66bb6a]`}>
                    </div>
                  </div>
                  <div className='flex flex-col'>
                    <div class={`h-3 w-10 md:w-16 bg-[#a5d6a7]`}>
                    </div>
                    <div class="font-bold text-right">
                      น้อย
                    </div>
                  </div>
                  <div className='ml-4 flex flex-col'>
                    <div class={`h-3 w-10 md:w-16 bg-[#94a3b8]`}>
                    </div>
                    <div class="font-bold text-center">
                      ไม่มีข้อมูล
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className='grid grid-cols-1 gap-4'>
            <div className='col-span-1'> */}
          <Chart
            containerProps={{ style: { height: "500px" } }}
            className='p-4'
            options={{
              type: windowDimensions.width <= 768 ? 'bar' : 'column',
              title: '',
              xAxis: {
                type: 'category',
                crosshair: true,
                categories: data.group_province.map(item => item.province_name),
                labels: {
                  rotation: windowDimensions.width <= 768 ? 0 : -90,
                }
              },
              plotOptions: {
                column: {
                  // pointPadding: 0.05,
                  borderWidth: 0
                },
              },
            }}
            data={
              [{
                name: 'จำนวนโครงการ',
                data: data.group_province.map(item => item.countProject),
              }]
            } />
          <RefDataDate
            title=""
            subjectTitle="*ประมวลผล ณ เดือนเมษายน 2565"
          />
        </div >
      </div >
    </Layoutas >
  )

}

export default PagePMS
